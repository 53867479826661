export default class TranslationStorage {
    /** @var {string} */
    locale;

    /** @var {Store} */
    store;

    /** @var {string} */
    namespace;

    constructor(locale, store, namespace) {
        this.locale = locale;
        this.store = store;
        this.namespace = namespace;
    }

    async seedStore() {
        this.store.commit(`${this.namespace}/setLocale`, { locale: this.locale });

        return this.store.dispatch(`${this.namespace}/loadTranslations`, { domain: 'frontend' });
    }

    /**
     * @param {string} domain
     *
     * @return {Promise<object>}
     */
    async load(domain) {
        return this.store.dispatch(`${this.namespace}/loadTranslations`, { domain });
    }

    /**
     * @param {string} key
     * @param {string} domain
     *
     * @return {bool}
     */
    hasTranslation(key, domain) {
        return this.store.getters[`${this.namespace}/hasTranslationTemplate`](key, domain);
    }

    isDomainLoaded(domain) {
        return this.store.getters[`${this.namespace}/isDomainLoaded`](domain);
    }

    /**
     * @param {string} key
     * @param {string} domain
     *
     * @return {string}
     */
    getTranslation(key, domain) {
        return this.store.getters[`${this.namespace}/getTranslationTemplate`](key, domain);
    }

    /**
     * @deprecated Should be moved to Translator
     */
    selectMessage(message, amount) {
        // const messages = message.match(/(?:\|\||[^\|])+/g);
        const messages = message.match(/(?:\|\||[^|])+/g);

        const explicitRules = {};
        const standardRules = [];

        // The options are defined by either the interval notation (explicit) or indexed (standard)
        // const explicitRegex = /^\s*((\{\s*(\-?\d+[\s*,\s*\-?\d+]*)\s*\})|([\[\]])\s*(-Inf|\-?\d+)\s*,\s*(\+?Inf|\-?\d+)\s*([\[\]]))\s?(.+?)$/;
        // const standardRegex = /^\w+\: +(.+)$/;
        //
        // const intervalRegex = /^\s*(\{\s*(\-?\d+[\s*,\s*\-?\d+]*)\s*\})|([\[\]])\s*(-Inf|\-?\d+)\s*,\s*(\+?Inf|\-?\d+)\s*([\[\]])/;
        // The options are defined by either the interval notation (explicit) or indexed (standard)
        const explicitRegex = /^\s*(({\s*(-?\d+[\s*,\s*\-?\d+]*)\s*})|([[\]])\s*(-Inf|-?\d+)\s*,\s*(\+?Inf|-?\d+)\s*([[\]]))\s?(.+?)$/;
        const standardRegex = /^\w+: +(.+)$/;

        const intervalRegex = /^\s*({\s*(-?\d+[\s*,\s*\-?\d+]*)\s*})|([[\]])\s*(-Inf|-?\d+)\s*,\s*(\+?Inf|-?\d+)\s*([[\]])/;

        messages.forEach((rawOption) => {
            const option = rawOption.replace('||', '|').trim();

            if (explicitRegex.test(option)) {
                // The interval notation was used
                const matches = option.match(explicitRegex);
                explicitRules[matches[0]] = matches[matches.length - 1];
            } else if (standardRegex.test(option)) {
                // The index notation was used and included a label
                const matches = option.match(standardRegex);
                standardRules.push(matches[1]);
            } else {
                // The index notation was used
                standardRules.push(option);
            }
        });

        // Check the explicit rules first
        const match = Object.entries(explicitRules).find(([rawInterval]) => {
            const interval = rawInterval.trim();

            const matches = interval.match(intervalRegex);
            if (matches[1]) {
                // A specific interval was used, e.g. {2} or {2,3}
                const numbers = matches[2].split(',').map(TranslationStorage.convertNumber);
                if (numbers.includes(amount)) {
                    return true;
                }
            } else {
                // A range interval was used, e.g. [-Inf,0] or ]1,Inf]
                const leftNumber = TranslationStorage.convertNumber(matches[4]);
                const rightNumber = TranslationStorage.convertNumber(matches[5]);

                if ((matches[3] === '[' ? amount >= leftNumber : amount > leftNumber)
                    && (matches[6] === ']' ? amount <= rightNumber : amount < rightNumber)) {
                    return true;
                }
            }

            return false;
        });

        if (match) {
            return match[1];
        }

        return standardRules[this.standardPosition(amount)] || standardRules[0];
    }

    standardPosition(amount) {
        switch (this.locale) {
            case 'az':
            case 'bo':
            case 'dz':
            case 'id':
            case 'ja':
            case 'jv':
            case 'ka':
            case 'km':
            case 'kn':
            case 'ko':
            case 'ms':
            case 'th':
            case 'tr':
            case 'vi':
            case 'zh':
                return 0;

            case 'af':
            case 'bn':
            case 'bg':
            case 'ca':
            case 'da':
            case 'de':
            case 'el':
            case 'en':
            case 'eo':
            case 'es':
            case 'et':
            case 'eu':
            case 'fa':
            case 'fi':
            case 'fo':
            case 'fur':
            case 'fy':
            case 'gl':
            case 'gu':
            case 'ha':
            case 'he':
            case 'hu':
            case 'is':
            case 'it':
            case 'ku':
            case 'lb':
            case 'ml':
            case 'mn':
            case 'mr':
            case 'nah':
            case 'nb':
            case 'ne':
            case 'nl':
            case 'nn':
            case 'no':
            case 'om':
            case 'or':
            case 'pa':
            case 'pap':
            case 'ps':
            case 'pt':
            case 'so':
            case 'sq':
            case 'sv':
            case 'sw':
            case 'ta':
            case 'te':
            case 'tk':
            case 'ur':
            case 'zu':
                return (amount === 1) ? 0 : 1;

            case 'am':
            case 'bh':
            case 'fil':
            case 'fr':
            case 'gun':
            case 'hi':
            case 'hy':
            case 'ln':
            case 'mg':
            case 'nso':
            case 'br':
            case 'ti':
            case 'wa':
                return ((amount === 0) || (amount === 1)) ? 0 : 1;

            case 'be':
            case 'bs':
            case 'hr':
            case 'ru':
            case 'sr':
            case 'uk':
                switch (true) {
                    case ((amount % 10 === 1) && (amount % 100 !== 11)): return 0;
                    case (((amount % 10 >= 2) && (amount % 10 <= 4) && ((amount % 100 < 10) || (amount % 100 >= 20)))): return 1;
                    default: return 2;
                }

            case 'cs':
            case 'sk':
                switch (true) {
                    case (amount === 1): return 0;
                    case ((amount >= 2) && (amount <= 4)): return 1;
                    default: return 2;
                }

            case 'ga':
                switch (true) {
                    case (amount === 1): return 0;
                    case (amount === 2): return 1;
                    default: return 2;
                }

            case 'lt':
                switch (true) {
                    case ((amount % 10 === 1) && (amount % 100 !== 11)): return 0;
                    case ((amount % 10 >= 2) && ((amount % 100 < 10) || (amount % 100 >= 20))): return 1;
                    default: return 2;
                }

            case 'sl':
                switch (true) {
                    case (amount % 100 === 1): return 0;
                    case (amount % 100 === 2): return 1;
                    case ((amount % 100 === 3) || (amount % 100 === 4)): return 2;
                    default: return 3;
                }

            case 'mk':
                return (amount % 10 === 1) ? 0 : 1;

            case 'mt':
                switch (true) {
                    case (amount === 1): return 0;
                    case ((amount === 0) || ((amount % 100 > 1) && (amount % 100 < 11))): return 1;
                    case ((amount % 100 > 10) && (amount % 100 < 20)): return 2;
                    default: return 3;
                }

            case 'lv':
                switch (true) {
                    case (amount === 0): return 0;
                    case ((amount % 10 === 1) && (amount % 100 !== 11)): return 1;
                    default: return 2;
                }

            case 'pl':
                switch (true) {
                    case (amount === 1): return 0;
                    case ((amount % 10 >= 2) && (amount % 10 <= 4) && ((amount % 100 < 12) || (amount % 100 > 14))): return 1;
                    default: return 2;
                }

            case 'cy':
                switch (true) {
                    case (amount === 1): return 0;
                    case (amount === 2): return 1;
                    case ((amount === 8) || (amount === 11)): return 2;
                    default: return 3;
                }

            case 'ro':
                switch (true) {
                    case (amount === 1): return 0;
                    case ((amount === 0) || ((amount % 100 > 0) && (amount % 100 < 20))): return 1;
                    default: return 2;
                }

            case 'ar':
                switch (true) {
                    case (amount === 0): return 0;
                    case (amount === 1): return 1;
                    case (amount === 2): return 2;
                    case (((amount % 100 >= 3) && (amount % 100 <= 10))): return 3;
                    case ((amount % 100 >= 11) && (amount % 100 <= 99)): return 4;
                    default: return 5;
                }

            default:
                return 0;
        }
    }

    static convertNumber(number) {
        if (number === '-Inf') {
            return Number.NEGATIVE_INFINITY;
        }

        if (number === '+Inf' || number === 'Inf') {
            return Number.POSITIVE_INFINITY;
        }

        return parseInt(number, 10);
    }
}
